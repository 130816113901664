import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../components/Layout/styled"

import Footer from "../../components/Layout/Footer"

const communique = ({ data, intl }) => {
  return (
    <Fragment>
      <SectionContainer>
        <SectionInnerWrapper align="flex-start" justify="flex-start">
          <Title>Communiqué</Title>
          <TextBox>
            <p>
              Suite à la publication d’une vidéo à charge contre PERCKO sur les
              réseaux sociaux le dimanche 26 septembre 2021, nous avons souhaité
              réagir :
            </p>
            <p>
              Nous sommes à l’écoute des spécialistes, et regrettons le ton et
              la manière utilisés dans cette vidéo.
            </p>
            <p>
              Nous avons fondé PERCKO en 2014 avec une équipe de passionnés.
              Depuis 7 ans, nous sommes entourés de professionnels de la santé
              pour apporter des solutions complémentaires, simples d’utilisation
              et qui s’intègrent au mieux dans le quotidien. Nos dispositifs
              accompagnent aujourd’hui de nombreux utilisateurs dont certains
              ont même souhaité rejoindre les rangs de PERCKO et contribuent
              aujourd’hui à son développement. 
            </p>
            <p>
              Souhaitant vulgariser la complexe problématique qu’est le mal de
              dos, nous avons rédigé des pages sur différentes pathologies. Afin
              d’éviter tout malentendu, nous prenons des actions immédiates pour
              apporter des améliorations à ces pages avec des professionnels de
              santé partenaires. Nous les voulons les plus pertinentes
              possibles.
            </p>
            <p>
              Dans une démarche d’amélioration continue, nous échangeons au
              quotidien avec de nombreux experts et nous continuons à inviter
              tous les experts qui le souhaitent à nous contacter sur{" "}
              <a href="mailto:fondateurs@percko.com">fondateurs@percko.com</a>.
              Tous ces avis sont clés à nos yeux et sont pris en compte dans les
              futurs développements.
            </p>
            <p>Alexis & Quentin</p>
          </TextBox>
        </SectionInnerWrapper>
      </SectionContainer>
      {data.allDirectusFooterTranslation.nodes.length > 0 && (
        <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
      )}
    </Fragment>
  )
}

const Title = styled.h1`
  color: #262626;
  font-size: 28px;
  line-height: 1.05;
  letter-spacing: 0.05em;
  font-family: "BebasNeueBold";
  font-weight: bold;
`

const TextBox = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  letter-spacing: 0;

  h2 {
    font-size: 14px;
    line-height: 24px;
    font-family: "GothamLight";
    margin: 0px;
  }

  > p {
    margin-bottom: 1.3em;
    color: #262626;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "mentions_legales" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(communique)
